import styled from "styled-components";

const Banner = styled.h1`
  font-family: "Rochester";
  font-style: normal;
  font-weight: normal;
  font-size: ${props => !props.small ? `144px` : `48px`};
  line-height: 185px;
  ${props => props.small ? `line-height: 1; margin: 0;` : ``}
  color: rgba(136, 37, 215, 0.53);
  margin-bottom: 0;
`;

export default Banner;