import React, { Component } from 'react'
import NameDialog from './components/NameDialog'
import Banner from './components/Banner'
import Subtitle from './components/Subtitle'
import Button from './components/Button'
import { Navbar, Level, Tag, NavbarItem, NavbarStart, NavbarEnd, Box, Container, Notification, Delete, Hero, HeroBody, Title, Field, Label, Input, Control, LevelItem } from 'bloomer';
import { db } from './services/firebase'

export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inRoom: false,
      name: '',
      nameDialogOpen: false,
      questions: [],
      displayAnswers: false,
      currentQuestion: 0,
      currentAnswer: '',
      currentQuestionText: '',
      quizActive: false,
      timer: 0,
    }

    this.handleClick = this.handleClick.bind(this);
    this.joinParty = this.joinParty.bind(this);
    this.leaveParty = this.leaveParty.bind(this);
    this.updateActiveQuestion = this.updateActiveQuestion.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.previousQuestion = this.previousQuestion.bind(this);

    this.populateQuestions();
  }

  componentDidMount() {
    db.collection("quiz").doc("gf2EOEVkIfZcFB1O0CcT")
      .onSnapshot((doc) => {
        this.updateQuizActive(doc.data().active);
        this.updateActiveQuestion(doc.data().currentQuestion);
        this.updateAnswersDisplayed(doc.data().displayAnswers);
      });
  }

  updateQuizActive(active) {
    this.setState({ quizActive: active });
  }

  updateActiveQuestion(question) {
    if (this.state.questions.length > 0) {
      this.setState({ currentQuestion: question, currentQuestionText: this.htmlDecode(this.state.questions[question].question), currentAnswer:  this.htmlDecode(this.state.questions[question].correct_answer)});
    }
  }

  updateAnswersDisplayed(display) {
    this.setState({ displayAnswers: display });
  }

  async populateQuestions() {
    const questionsRef = await db.collection('questions').get();

    if (questionsRef.docs.length == 0) {
      const batch = db.batch();

      fetch('https://opentdb.com/api.php?amount=60&category=9&type=multiple')
        .then(response => response.json())
        .then(data => {
          // this.setState({ questions: data.results });
          data.results.forEach(question => {

            const docRef = db.collection('questions').doc(); //automatically generate unique id
            batch.set(docRef, question);
          });

          // batch.commit();
        });
    } else {
      this.setState({ questions: questionsRef.docs.map(doc => doc.data()) });
    }
  }

  previousQuestion() {
    db.collection('quiz').doc("gf2EOEVkIfZcFB1O0CcT").set({ currentQuestion: this.state.currentQuestion - 1 }, { merge: true });
  }

  nextQuestion() {
    db.collection('quiz').doc("gf2EOEVkIfZcFB1O0CcT").set({ currentQuestion: this.state.currentQuestion + 1 }, { merge: true });
  }

  handleClick() {
    if (this.state.name.length == 0) {
      this.setState({ nameDialogOpen: true });
    }
  }

  joinParty() {
    this.setState({ inRoom: true });
  }

  leaveParty() {
    this.setState({ inRoom: false });
  }

  htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  render() {
    return (
      <div>
        {this.state.inRoom &&
          <div>
            <Navbar>
              <Container>
                <NavbarStart>
                  <NavbarItem>
                    <Banner small>Mum's 60th!</Banner>
                  </NavbarItem>
                </NavbarStart>
                <NavbarEnd>
                  <NavbarItem>
                    <Button style={{ backgroundColor: 'white', color: 'black', textTransform: 'uppercase', marginRight: '1em' }} onClick={this.handleClick}>{this.state.name}</Button>
                    <Button onClick={this.leaveParty}>LEAVE THE PARTY</Button>
                  </NavbarItem>
                </NavbarEnd>
              </Container>
            </Navbar>
            <Hero isSize="small">
              <HeroBody>
                <Container hasTextAlign='centered'>
                  <Title>The Great Sixty Quiz</Title>
                </Container>
              </HeroBody>
            </Hero>
            <Container style={{ padding: '0 20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Notification isColor="info" isHidden={this.state.quizActive}>
                <Delete />
                Welcome - the quiz will begin shortly, please stand by!
              </Notification>
              {this.state.quizActive &&
                <div style={{marginBottom: '1em'}}>
                  <Tag style={{marginBottom: '1em'}} isColor="info">Question {this.state.currentQuestion + 1}</Tag>
                  <Box style={{ maxWidth: '1000px', fontSize: '20px' }}>{this.state.currentQuestionText}</Box>
                  {this.state.displayAnswers &&
                    <Box style={{ maxWidth: '1000px', fontSize: '20px' }}>{this.state.currentAnswer}</Box>
                  }
              </div>
              }
              {/* <Box style={{ minWidth: '400px', maxWidth: '1000px', padding: '0' }}>
                <Field>
                  <Control>
                    <Input style={{ border: 0, boxShadow: 'none', fontSize: '20px', padding: '20px', textAlign: 'center', height: '70px', borderRadius: '8px' }} type="text" placeholder='Answer' />
                  </Control>
                </Field>
              </Box> */}
            {this.state.name == 'Henry' &&
              <Level>
                <LevelItem>
                  <Button onClick={this.previousQuestion}>Previous Question</Button>
                </LevelItem>
                <LevelItem>
                  <Button onClick={this.nextQuestion}>Next Question</Button>
                </LevelItem>
              </Level>
            }
            </Container>
          </div>
        }
        {!this.state.inRoom &&
          <main>
            <NameDialog open={this.state.nameDialogOpen} name={this.state.name} onSubmit={this.joinParty} setName={(name) => this.setState({ name: name })} onClose={() => this.setState({ nameDialogOpen: false })} />
            <Banner>Mum's 60th!</Banner>
            <Subtitle>HAPPY BIRTHDAY!</Subtitle>
            <Button onClick={this.handleClick}>JOIN THE PARTY</Button>
          </main>
        }
      </div>
    )
  }
}
