import styled from "styled-components";

const Subtitle = styled.h2`
  font-family: "Reem Kufi";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  letter-spacing: 0.20em;
  margin-top: 0;
  margin-bottom: 1em;
`;

export default Subtitle;