import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBW8eqCF12GzItnkG_-rXxwqMwjhTmf7u0",
  authDomain: "mumparty-29655.firebaseapp.com",
  databaseURL: "https://mumparty-29655.firebaseio.com",
  projectId: "mumparty-29655",
  storageBucket: "mumparty-29655.appspot.com",
  messagingSenderId: "710379752731",
  appId: "1:710379752731:web:543aaa430107f820d397be"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();