import styled from "styled-components";

const Button = styled.button`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.2em;
  color: white;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.50);
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  padding: 12px 24px;
  transition: all 0.2s ease-in-out;
  background: #A46AD2;

  &:hover, 
  &:focus {
    opacity: 0.9;
    filter: alpha(opacity=90);
    transform: scale(1.1);
  }
`;

export default Button;