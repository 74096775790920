import React from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import styled from 'styled-components';

export default function NameDialog(props) {
  const { onClose, open, name, setName, onSubmit } = props;
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState(null);
  const [disabled, setDisabled] = React.useState(true);

  const handleSubmit = () => {
    if (valid) {
      onSubmit();
      onClose();
    }
  }

  const DialogActionsCustom = styled(DialogActions)
    `
    flex: 0 0 auto;
    display: flex;
    padding: 8px 24px 16px 24px;
    align-items: center;
    justify-content: space-between;
  `;

  const valid = () => {
    return name.length > 0;
  }

  const validate = () => {
    if (!valid) {
      setError(true);
      setErrorText('Name is required');
      setDisabled(true);
    } else {
      setError(false);
      setDisabled(false);
    }
  }

  const handleChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} fullWidth={true} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">What's your name?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="name"
            value={name}
            onChange={handleChange}
            onKeyUp={validate}
            label="Name"
            type="text"
            error={error}
            helperText={errorText}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActionsCustom>
          <Button onClick={onClose} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={disabled} variant="contained" color="primary">
            Join
          </Button>
        </DialogActionsCustom>
      </Dialog>
    </div>
  );
}
